<template>
  <div class="Text part" :class="[ payload.optionen ]">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 text">
          <line-box v-if="boxed">
            <span v-html="payload.text" />
            <xstanding-link v-if="payload.link" :to="payload.link" class="link mt-5 block">
              {{ payload.linktext }}
            </xstanding-link>
          </line-box>
          <div v-else>
            <span v-html="payload.text" />
            <xstanding-link v-if="payload.link" :to="payload.link" class="link">
              {{ payload.linktext }}
            </xstanding-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    return {
      boxed: computed(() => props.payload.optionen?.includes('block')),
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

:deep(a) {
  // @extend .li2;
  @apply font-sans;
  color: var(--color-blue);
  font-size: pt(14);
  line-height: pt(20);
  font-weight: 600;
}

:deep(img) {
  max-height: 75vh;
}

:deep(h4) {
  @apply font-sans;
}
:deep(p:last-child),
:deep(h1:last-child),
:deep(h2:last-child),
:deep(h3:last-child) {
  margin-bottom: 0;
}
:deep(h2){
  font-weight: 300;
}

.link {
  @extend .li1;
  font-weight: 500;
}

.Text.colored {
  color: var(--color-area);
  @apply font-title;
}
</style>
